/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTalent = /* GraphQL */ `
  mutation CreateTalent(
    $input: CreateTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    createTalent(input: $input, condition: $condition) {
      owner
      first_name
      last_name
      birthdate
      email
      contactEmail
      disziplin
      phone
      residence
      city
      current_education
      current_education_year
      current_education_type
      highest_degree
      highest_degree_year
      last_employers
      type_of_employment
      careerLevel
      earliest_starting_date
      type_of_search
      gallery_images
      imageUrl
      design_upload_url
      text_upload_url
      discipline_specific_task
      work_areas
      favorite_work_tasks
      category_term
      languages
      source
      show_in_talentpool
      updatedAt
      isCsvImported
      tenant
      event_registrations {
        id
        location
        date
      }
      reminderEmailDateSent
      updatedProfileAt
      createdAt
    }
  }
`;
export const updateTalent = /* GraphQL */ `
  mutation UpdateTalent(
    $input: UpdateTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    updateTalent(input: $input, condition: $condition) {
      owner
      first_name
      last_name
      birthdate
      email
      contactEmail
      disziplin
      phone
      residence
      city
      current_education
      current_education_year
      current_education_type
      highest_degree
      highest_degree_year
      last_employers
      type_of_employment
      careerLevel
      earliest_starting_date
      type_of_search
      gallery_images
      imageUrl
      design_upload_url
      text_upload_url
      discipline_specific_task
      work_areas
      favorite_work_tasks
      category_term
      languages
      source
      show_in_talentpool
      updatedAt
      isCsvImported
      tenant
      event_registrations {
        id
        location
        date
      }
      reminderEmailDateSent
      updatedProfileAt
      createdAt
    }
  }
`;
export const deleteTalent = /* GraphQL */ `
  mutation DeleteTalent(
    $input: DeleteTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    deleteTalent(input: $input, condition: $condition) {
      owner
      first_name
      last_name
      birthdate
      email
      contactEmail
      disziplin
      phone
      residence
      city
      current_education
      current_education_year
      current_education_type
      highest_degree
      highest_degree_year
      last_employers
      type_of_employment
      careerLevel
      earliest_starting_date
      type_of_search
      gallery_images
      imageUrl
      design_upload_url
      text_upload_url
      discipline_specific_task
      work_areas
      favorite_work_tasks
      category_term
      languages
      source
      show_in_talentpool
      updatedAt
      isCsvImported
      tenant
      event_registrations {
        id
        location
        date
      }
      reminderEmailDateSent
      updatedProfileAt
      createdAt
    }
  }
`;
export const createAgentur = /* GraphQL */ `
  mutation CreateAgentur(
    $input: CreateAgenturInput!
    $condition: ModelAgenturConditionInput
  ) {
    createAgentur(input: $input, condition: $condition) {
      owner
      talents_yes
      talents_maybe
      talents_no
      createdAt
      updatedAt
    }
  }
`;
export const updateAgentur = /* GraphQL */ `
  mutation UpdateAgentur(
    $input: UpdateAgenturInput!
    $condition: ModelAgenturConditionInput
  ) {
    updateAgentur(input: $input, condition: $condition) {
      owner
      talents_yes
      talents_maybe
      talents_no
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgentur = /* GraphQL */ `
  mutation DeleteAgentur(
    $input: DeleteAgenturInput!
    $condition: ModelAgenturConditionInput
  ) {
    deleteAgentur(input: $input, condition: $condition) {
      owner
      talents_yes
      talents_maybe
      talents_no
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyLicenses = /* GraphQL */ `
  mutation CreateCompanyLicenses(
    $input: CreateCompanyLicensesInput!
    $condition: ModelCompanyLicensesConditionInput
  ) {
    createCompanyLicenses(input: $input, condition: $condition) {
      owner
      email
      talentpoolLicenseEndDate
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyLicenses = /* GraphQL */ `
  mutation UpdateCompanyLicenses(
    $input: UpdateCompanyLicensesInput!
    $condition: ModelCompanyLicensesConditionInput
  ) {
    updateCompanyLicenses(input: $input, condition: $condition) {
      owner
      email
      talentpoolLicenseEndDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyLicenses = /* GraphQL */ `
  mutation DeleteCompanyLicenses(
    $input: DeleteCompanyLicensesInput!
    $condition: ModelCompanyLicensesConditionInput
  ) {
    deleteCompanyLicenses(input: $input, condition: $condition) {
      owner
      email
      talentpoolLicenseEndDate
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      owner
      slug
      name
      teaserText
      description
      numberOfEmployees
      industry
      foundingYear
      locations {
        country
        region
        street
        city
        plz
      }
      targetingText
      logoUrl
      bannerUrl
      website
      linkedin
      facebook
      instagram
      twitter
      youtube
      isDRVMember
      isBDOMember
      isFARMember
      createdAt
      updatedAt
      jobs {
        items {
          owner
          slug
          title
          category
          employmentType
          city
          remoteType
          careerLevel
          description
          linkToApply
          emailContact
          isHighlight
          isColored
          expiresAt
          createdAt
          updatedAt
          id
          companyJobsId
        }
        nextToken
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      owner
      slug
      name
      teaserText
      description
      numberOfEmployees
      industry
      foundingYear
      locations {
        country
        region
        street
        city
        plz
      }
      targetingText
      logoUrl
      bannerUrl
      website
      linkedin
      facebook
      instagram
      twitter
      youtube
      isDRVMember
      isBDOMember
      isFARMember
      createdAt
      updatedAt
      jobs {
        items {
          owner
          slug
          title
          category
          employmentType
          city
          remoteType
          careerLevel
          description
          linkToApply
          emailContact
          isHighlight
          isColored
          expiresAt
          createdAt
          updatedAt
          id
          companyJobsId
        }
        nextToken
      }
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      owner
      slug
      name
      teaserText
      description
      numberOfEmployees
      industry
      foundingYear
      locations {
        country
        region
        street
        city
        plz
      }
      targetingText
      logoUrl
      bannerUrl
      website
      linkedin
      facebook
      instagram
      twitter
      youtube
      isDRVMember
      isBDOMember
      isFARMember
      createdAt
      updatedAt
      jobs {
        items {
          owner
          slug
          title
          category
          employmentType
          city
          remoteType
          careerLevel
          description
          linkToApply
          emailContact
          isHighlight
          isColored
          expiresAt
          createdAt
          updatedAt
          id
          companyJobsId
        }
        nextToken
      }
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      owner
      company {
        owner
        slug
        name
        teaserText
        description
        numberOfEmployees
        industry
        foundingYear
        locations {
          country
          region
          street
          city
          plz
        }
        targetingText
        logoUrl
        bannerUrl
        website
        linkedin
        facebook
        instagram
        twitter
        youtube
        isDRVMember
        isBDOMember
        isFARMember
        createdAt
        updatedAt
        jobs {
          nextToken
        }
      }
      slug
      title
      category
      employmentType
      city
      remoteType
      careerLevel
      description
      linkToApply
      emailContact
      isHighlight
      isColored
      expiresAt
      createdAt
      updatedAt
      id
      companyJobsId
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      owner
      company {
        owner
        slug
        name
        teaserText
        description
        numberOfEmployees
        industry
        foundingYear
        locations {
          country
          region
          street
          city
          plz
        }
        targetingText
        logoUrl
        bannerUrl
        website
        linkedin
        facebook
        instagram
        twitter
        youtube
        isDRVMember
        isBDOMember
        isFARMember
        createdAt
        updatedAt
        jobs {
          nextToken
        }
      }
      slug
      title
      category
      employmentType
      city
      remoteType
      careerLevel
      description
      linkToApply
      emailContact
      isHighlight
      isColored
      expiresAt
      createdAt
      updatedAt
      id
      companyJobsId
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      owner
      company {
        owner
        slug
        name
        teaserText
        description
        numberOfEmployees
        industry
        foundingYear
        locations {
          country
          region
          street
          city
          plz
        }
        targetingText
        logoUrl
        bannerUrl
        website
        linkedin
        facebook
        instagram
        twitter
        youtube
        isDRVMember
        isBDOMember
        isFARMember
        createdAt
        updatedAt
        jobs {
          nextToken
        }
      }
      slug
      title
      category
      employmentType
      city
      remoteType
      careerLevel
      description
      linkToApply
      emailContact
      isHighlight
      isColored
      expiresAt
      createdAt
      updatedAt
      id
      companyJobsId
    }
  }
`;
