/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchTalents = /* GraphQL */ `
  query SearchTalents(
    $filter: SearchableTalentFilterInput
    $sort: [SearchableTalentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTalentAggregationInput]
  ) {
    searchTalents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        owner
        first_name
        last_name
        birthdate
        email
        contactEmail
        disziplin
        phone
        residence
        city
        current_education
        current_education_year
        current_education_type
        highest_degree
        highest_degree_year
        last_employers
        type_of_employment
        careerLevel
        earliest_starting_date
        type_of_search
        gallery_images
        imageUrl
        design_upload_url
        text_upload_url
        discipline_specific_task
        work_areas
        favorite_work_tasks
        category_term
        languages
        source
        show_in_talentpool
        updatedAt
        isCsvImported
        tenant
        event_registrations {
          id
          location
          date
        }
        reminderEmailDateSent
        updatedProfileAt
        createdAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getTalent = /* GraphQL */ `
  query GetTalent($owner: String!) {
    getTalent(owner: $owner) {
      owner
      first_name
      last_name
      birthdate
      email
      contactEmail
      disziplin
      phone
      residence
      city
      current_education
      current_education_year
      current_education_type
      highest_degree
      highest_degree_year
      last_employers
      type_of_employment
      careerLevel
      earliest_starting_date
      type_of_search
      gallery_images
      imageUrl
      design_upload_url
      text_upload_url
      discipline_specific_task
      work_areas
      favorite_work_tasks
      category_term
      languages
      source
      show_in_talentpool
      updatedAt
      isCsvImported
      tenant
      event_registrations {
        id
        location
        date
      }
      reminderEmailDateSent
      updatedProfileAt
      createdAt
    }
  }
`;
export const listTalents = /* GraphQL */ `
  query ListTalents(
    $owner: String
    $filter: ModelTalentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTalents(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        first_name
        last_name
        birthdate
        email
        contactEmail
        disziplin
        phone
        residence
        city
        current_education
        current_education_year
        current_education_type
        highest_degree
        highest_degree_year
        last_employers
        type_of_employment
        careerLevel
        earliest_starting_date
        type_of_search
        gallery_images
        imageUrl
        design_upload_url
        text_upload_url
        discipline_specific_task
        work_areas
        favorite_work_tasks
        category_term
        languages
        source
        show_in_talentpool
        updatedAt
        isCsvImported
        tenant
        event_registrations {
          id
          location
          date
        }
        reminderEmailDateSent
        updatedProfileAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getAgentur = /* GraphQL */ `
  query GetAgentur($owner: String!) {
    getAgentur(owner: $owner) {
      owner
      talents_yes
      talents_maybe
      talents_no
      createdAt
      updatedAt
    }
  }
`;
export const listAgenturs = /* GraphQL */ `
  query ListAgenturs(
    $owner: String
    $filter: ModelAgenturFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAgenturs(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        talents_yes
        talents_maybe
        talents_no
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyLicenses = /* GraphQL */ `
  query GetCompanyLicenses($owner: String!) {
    getCompanyLicenses(owner: $owner) {
      owner
      email
      talentpoolLicenseEndDate
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyLicenses = /* GraphQL */ `
  query ListCompanyLicenses(
    $owner: String
    $filter: ModelCompanyLicensesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyLicenses(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        email
        talentpoolLicenseEndDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const talentsByDate = /* GraphQL */ `
  query TalentsByDate(
    $tenant: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTalentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    talentsByDate(
      tenant: $tenant
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        first_name
        last_name
        birthdate
        email
        contactEmail
        disziplin
        phone
        residence
        city
        current_education
        current_education_year
        current_education_type
        highest_degree
        highest_degree_year
        last_employers
        type_of_employment
        careerLevel
        earliest_starting_date
        type_of_search
        gallery_images
        imageUrl
        design_upload_url
        text_upload_url
        discipline_specific_task
        work_areas
        favorite_work_tasks
        category_term
        languages
        source
        show_in_talentpool
        updatedAt
        isCsvImported
        tenant
        event_registrations {
          id
          location
          date
        }
        reminderEmailDateSent
        updatedProfileAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($owner: String!) {
    getCompany(owner: $owner) {
      owner
      slug
      name
      teaserText
      description
      numberOfEmployees
      industry
      foundingYear
      locations {
        country
        region
        street
        city
        plz
      }
      targetingText
      logoUrl
      bannerUrl
      website
      linkedin
      facebook
      instagram
      twitter
      youtube
      isDRVMember
      isBDOMember
      isFARMember
      createdAt
      updatedAt
      jobs {
        items {
          owner
          slug
          title
          category
          employmentType
          city
          remoteType
          careerLevel
          description
          linkToApply
          emailContact
          isHighlight
          isColored
          expiresAt
          createdAt
          updatedAt
          id
          companyJobsId
        }
        nextToken
      }
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $owner: String
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        slug
        name
        teaserText
        description
        numberOfEmployees
        industry
        foundingYear
        locations {
          country
          region
          street
          city
          plz
        }
        targetingText
        logoUrl
        bannerUrl
        website
        linkedin
        facebook
        instagram
        twitter
        youtube
        isDRVMember
        isBDOMember
        isFARMember
        createdAt
        updatedAt
        jobs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      owner
      company {
        owner
        slug
        name
        teaserText
        description
        numberOfEmployees
        industry
        foundingYear
        locations {
          country
          region
          street
          city
          plz
        }
        targetingText
        logoUrl
        bannerUrl
        website
        linkedin
        facebook
        instagram
        twitter
        youtube
        isDRVMember
        isBDOMember
        isFARMember
        createdAt
        updatedAt
        jobs {
          nextToken
        }
      }
      slug
      title
      category
      employmentType
      city
      remoteType
      careerLevel
      description
      linkToApply
      emailContact
      isHighlight
      isColored
      expiresAt
      createdAt
      updatedAt
      id
      companyJobsId
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        owner
        company {
          owner
          slug
          name
          teaserText
          description
          numberOfEmployees
          industry
          foundingYear
          targetingText
          logoUrl
          bannerUrl
          website
          linkedin
          facebook
          instagram
          twitter
          youtube
          isDRVMember
          isBDOMember
          isFARMember
          createdAt
          updatedAt
        }
        slug
        title
        category
        employmentType
        city
        remoteType
        careerLevel
        description
        linkToApply
        emailContact
        isHighlight
        isColored
        expiresAt
        createdAt
        updatedAt
        id
        companyJobsId
      }
      nextToken
    }
  }
`;
export const searchJobs = /* GraphQL */ `
  query SearchJobs(
    $filter: SearchableJobFilterInput
    $sort: [SearchableJobSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableJobAggregationInput]
  ) {
    searchJobs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        owner
        company {
          owner
          slug
          name
          teaserText
          description
          numberOfEmployees
          industry
          foundingYear
          targetingText
          logoUrl
          bannerUrl
          website
          linkedin
          facebook
          instagram
          twitter
          youtube
          isDRVMember
          isBDOMember
          isFARMember
          createdAt
          updatedAt
        }
        slug
        title
        category
        employmentType
        city
        remoteType
        careerLevel
        description
        linkToApply
        emailContact
        isHighlight
        isColored
        expiresAt
        createdAt
        updatedAt
        id
        companyJobsId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
