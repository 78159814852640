const theme = {
    borderRadius: '16px',
    borderRadiusBig: '32px',
    borderRadiusSection: '100px',
    borderRadiusSectionMobile: '60px',
    colors: {
        fontBlack: '#202b57',
        fontLight: '#6e6e73',
        fontWhite: '#FCFCFC',
        darkmodeBg: '#18191F',
        lightBg: '#F5F5F7',
        buttonPrimary: '#202b57',
        buttonLink: '#0066CC',
        buttonPrimaryHover: '#3a456f',
        buttonSubtle: '#EBF5FF',
        buttonSubtleError: '#FFEBEB',
        buttonSubtleErrorHover: '#FFD2D2',
        primary: '#FFDE44',
        primaryDark: '#dda002',
        primaryDarkmode: '#BCA4FF',
        primaryLight: '#FEE093',
        primaryBg: '#393D3F',
        primaryBgLight: '#fffcf5',
        primaryDisabled: '#87c2fc',
        secondary: '#202b57',
        secondaryDark: '#0096B7',
        secondaryDarkmode: '#82E9FF',
        secondaryLight: '#DEF9FF',
        secondaryBg: '#F1FCFF',
        eyebrow: '#BF4800',
        error: '#e30000',
        success: '#00BA88',
        warning: '#FFED00',
        brColor: '#f3f3f4',
    }
};

export { theme };

