import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Company } from "../../../shared/model/Company";
import { executeFetchCompany } from "../services/CompanyService";
import type { AppState } from "./store";

const calculateProfileCompletionInPercent = (company: Company) => {
  if (company == null) return 0;

  const numberOfAttributes = Object.keys(company).length;
  const numberOfValues = Object.values(company).filter(
    (value) => value != null && value.length > 0
  ).length;
  return Math.round((numberOfValues / numberOfAttributes) * 100);
};

export interface CompanyState {
  value: Company | null;
  status: "idle" | "loading" | "failed";
  profileCompletion: number | null;
}

const initialState: CompanyState = {
  value: null,
  status: "idle",
  profileCompletion: 0,
};

const initialCompany: Company = {
  slug: "",
  name: "",
  teaserText: "",
  description: "",
  numberOfEmployees: "",
  industry: "",
  foundingYear: "",
  locations: [],
  targetingText: "",
  logoUrl: "",
  bannerUrl: "",
  website: "",
  linkedin: "",
  facebook: "",
  instagram: "",
  twitter: "",
  youtube: "",
  isDRVMember: false,
  isBDOMember: false,
  isFARMember: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchAsync = createAsyncThunk("company/fetch", async () => {
  const response = await executeFetchCompany();
  let company: Company = response.data.getCompany;

  if (company == null) {
    company = initialCompany;
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    company: company,
  };
});

export const companySlice = createSlice({
  name: "company",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    reset: () => initialState,
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.company.isBDOMember == null) {
          action.payload.company.isBDOMember = false;
        }
        if (action.payload.company.isFARMember == null) {
          action.payload.company.isFARMember = false;
        }
        state.value = action.payload.company;
        state.profileCompletion = calculateProfileCompletionInPercent(
          action.payload.company
        );
      });
  },
});

export const { reset } = companySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCompany = (state: AppState) => state.company.value;
export const selectProfileCompletion = (state: AppState) =>
  state.company.profileCompletion;

export default companySlice.reducer;
