/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:2ca2ab9f-ea87-4b5a-98e7-8b1b51599f8c",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_EW3suQEXb",
    "aws_user_pools_web_client_id": "68oc0dcjl1dlunckporgqn6vci",
    "oauth": {
        "domain": "easyboardingd289c598-d289c598-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://easyboarding.de/login/success/,http://localhost:3000/login/success/,https://easyboarding.de/login/success/",
        "redirectSignOut": "https://easyboarding.de/,http://localhost:3000/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://5xgsgyyohvbolfbywfwl6fwdru.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fgcbnmyo4fanvdrxrdq3caypwq",
    "aws_user_files_s3_bucket": "easyboardings3bucket104622-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
